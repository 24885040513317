'use client';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import debounce from 'lodash/debounce';

export type Testimonial = {
	id: number;
	name: string;
	occupation?: string;
	location: string;
	text: string;
};

export type TestimonialsProps = {
	className?: string;
	testimonials: Testimonial[];
};

export default function Testimonials(props: TestimonialsProps) {
	const { className, testimonials } = props;

	const [carouselEl, setCarouselEl] = useState<HTMLDivElement | null>(null);
	const testimonialsRefs = useMemo(() => Array(testimonials.length).fill(null), [testimonials]);
	const visibleTestimonialsRefs = useRef<Array<HTMLDivElement>>([]);
	const observerRef = useRef<IntersectionObserver | null>(null);
	const currentTestimonialIndex = useRef(0);
	const isScrolling = useRef(false);

	const scrollToTestimonialIndex = useCallback(
		(index: number, carouselEl: HTMLDivElement) => {
			if (isScrolling.current || !carouselEl) return;
			const testimonialEl = testimonialsRefs[index];
			if (testimonialEl) {
				currentTestimonialIndex.current = index;
				carouselEl.scrollTo({ left: testimonialEl.offsetLeft, behavior: 'smooth' });
			}
		},
		[testimonialsRefs],
	);

	useEffect(() => {
		const resizeHandler = () => {
			if (carouselEl) {
				scrollToTestimonialIndex(currentTestimonialIndex.current, carouselEl);
			}
		};

		window.addEventListener('resize', resizeHandler);

		observerRef.current = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					visibleTestimonialsRefs.current.push(entry.target as HTMLDivElement);
				} else {
					visibleTestimonialsRefs.current = visibleTestimonialsRefs.current.filter(
						(ref) => ref !== entry.target,
					);
				}
				visibleTestimonialsRefs.current.sort((a, b) => a.offsetLeft - b.offsetLeft);
			});
		});

		return () => {
			observerRef.current?.disconnect();
			document.removeEventListener('resize', resizeHandler);
		};
	}, [carouselEl, scrollToTestimonialIndex]);

	const onScrollEndDebounced = useRef(
		debounce(
			(carouselEl: HTMLDivElement) => {
				isScrolling.current = false;

				const scrollLeft = carouselEl.scrollLeft;
				let closestTestimonialEl: HTMLDivElement | null = null;
				let closestTestimonialIndex: number | null = null;

				testimonialsRefs.forEach((testimonialEl, index) => {
					if (!testimonialEl) return;
					if (
						closestTestimonialEl === null ||
						Math.abs(testimonialEl.offsetLeft - scrollLeft) <
							Math.abs(closestTestimonialEl.offsetLeft - scrollLeft)
					) {
						closestTestimonialEl = testimonialEl;
						closestTestimonialIndex = index;
					}
				});

				if (closestTestimonialEl && closestTestimonialIndex !== null) {
					if (scrollLeft !== (closestTestimonialEl as HTMLDivElement).offsetLeft) {
						scrollToTestimonialIndex(closestTestimonialIndex, carouselEl);
					}
				}
			},
			50,
			{ leading: false, trailing: true },
		),
	).current;

	const scrollToNext = () => {
		if (!carouselEl) return;

		const firstVisibleTestimonial = visibleTestimonialsRefs.current[0];
		const lastVisibleTestimonial = visibleTestimonialsRefs.current[visibleTestimonialsRefs.current.length - 1];

		if (lastVisibleTestimonial === testimonialsRefs[testimonialsRefs.length - 1]) {
			scrollToTestimonialIndex(0, carouselEl);
		} else {
			scrollToTestimonialIndex(testimonialsRefs.indexOf(firstVisibleTestimonial) + 1, carouselEl);
		}
	};

	const scrollToPrevious = () => {
		if (!carouselEl) return;

		const firstVisibleTestimonial = visibleTestimonialsRefs.current[0];

		if (firstVisibleTestimonial === testimonialsRefs[0]) {
			scrollToTestimonialIndex(testimonialsRefs.length - 1, carouselEl);
		} else {
			scrollToTestimonialIndex(testimonialsRefs.indexOf(firstVisibleTestimonial) - 1, carouselEl);
		}
	};

	useEffect(() => {
		const onScroll = () => {
			if (!carouselEl) return;
			isScrolling.current = true;
			onScrollEndDebounced(carouselEl);
		};

		if (carouselEl) {
			carouselEl.addEventListener('scroll', onScroll);

			const observer = observerRef.current;
			testimonialsRefs.forEach((testimonialEl) => {
				if (testimonialEl) {
					observer?.observe(testimonialEl);
				}
			});

			return () => {
				carouselEl.removeEventListener('scroll', onScroll);
				observer?.disconnect();
			};
		}
	}, [carouselEl, onScrollEndDebounced, testimonialsRefs]);

	return (
		<div className={`testimonial-container ${className ?? ''}`}>
			<div ref={setCarouselEl} className="testimonial-carousel">
				{testimonials.map((testimonial, index) => (
					<div
						key={testimonial.id}
						className="testimonial-item"
						ref={(ref) => (testimonialsRefs[index] = ref)}
					>
						<p className="testimonial-text quote-style">{testimonial.text}</p>
						<p className="testimonial-author">
							<span className="name">{testimonial.name}</span>,{' '}
							{testimonial.occupation && (
								<>
									<span className="occupation">{testimonial.occupation}</span>,{' '}
								</>
							)}
							<span className="location">{testimonial.location}</span>
						</p>
					</div>
				))}
			</div>
			<button className="carousel-btn prev-btn" onClick={scrollToPrevious} aria-label="Previous Testimonial">
				<IconChevronLeft size={50} />
			</button>
			<button className="carousel-btn next-btn" onClick={scrollToNext} aria-label="Next Testimonial">
				<IconChevronRight size={50} />
			</button>
		</div>
	);
}
